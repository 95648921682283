@charset "UTF-8";
@font-face {
  font-family: "Cuprum";
  font-style: bold;
  font-weight: 700;
  src: url("./assets/fonts/Cuprum-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "handsome-nib";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/handsomenib.ttf") format("truetype");
}
.break-word-hyphen {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

img {
  max-width: 100%;
}

.bg-img__10-16, .bg-img__round, .bg-img__square, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img__2-1, .bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.bg-img__contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-img__2-1 {
  padding-top: 50%;
}
.bg-img__16-9 {
  padding-top: 56.25%;
}
.bg-img__16-10 {
  padding-top: 62.5%;
}
.bg-img__4-3 {
  padding-top: 75%;
}
.bg-img__square {
  padding-top: 100%;
}
.bg-img__round {
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}
.bg-img__overlay:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.25;
}
.bg-img__10-16 {
  padding-top: 160%;
}

.slider{
  position: relative;
  display: block;
  width: 100%;
}
@media (max-width: 480px) {
  .slider {
    padding: 0px 15% !important;
  }
  .slider .slick-list {
    overflow: visible !important;
  }
}

.slick-slider{
  position: relative;
  box-sizing: border-box;
  display: block;
  -webkit-user-select: none;
          user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list{
  position: relative;
  margin: 0px;
  display: block;
  height: auto;
  overflow: hidden;
  padding: 0px;
  cursor: grab;
}
.slick-list:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.slick-list.dragging {
  cursor: move;
  cursor: grabbing;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track{
  position: relative;
  left: 0px;
  top: 0px;
  margin-right: auto;
  height: auto;
  outline: 2px solid transparent;
  outline-offset: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  display: -ms-flexbox;
}
.slick-loading .slick-track{
  visibility: hidden;
}

.slick-slide{
  display: none;
  min-height: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.slick-slide .slide{
  position: relative;
}
.slick-slide img{
  display: block;
}
.slick-slide.slick-loading img{
  display: none;
}
.slick-slide.dragging a, .slick-slide.dragging img{
  pointer-events: none;
}
.slick-initialized .slick-slide{
  display: block;
}
.slick-loading .slick-slide{
  visibility: hidden;
}

.slick-arrow{
  cursor: pointer;
}
.slick-arrow.slick-hidden{
  display: none;
}

.slick-prev, .slick-next{
  position: absolute;
  top: 100%;
  z-index: 10;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.slick-prev .fa-angle-right::before, .slick-prev .fa-angle-left::before, .slick-next .fa-angle-right::before, .slick-next .fa-angle-left::before{
  font-size: 1.75rem;
}

.slick-prev{
  position: absolute;
  left: 0.25rem;
  top: 50%;
  margin-right: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transform: translate(-100%, -50%);
}
.slick-next{
  position: absolute;
  right: 0.25rem;
  top: 50%;
  margin-left: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transform: translate(100%, -50%);
}
.slick-dots{
  list-style-type: none;
  padding-top: 0.75rem;
  padding-left: 0px;
  text-align: center;
  line-height: 0;
}
.slick-dots > li{
  display: inline-block;
  padding: 0.25rem;
  vertical-align: top;
}
.slick-dots > li + li{
  margin-left: 0.25rem;
}
.slick-dots > li > button{
  display: block;
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
  overflow: hidden;
  border-radius: 9999px;
  width: 0.625rem;
  height: 0.625rem;
  color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.slick-dots > li.slick-active > button{
  --tw-bg-opacity: 1;
  background-color: rgb(200 72 29 / var(--tw-bg-opacity));
}

@media (max-width: 480px) {
  .slick-track {
    position: relative;
    left: -21%;
  }
}
.webkit-intro-container{
  position: relative;
  margin-bottom: 2.5rem;
}
@media (max-width: 1200px){
  .webkit-intro-container{
    margin-bottom: 4.166666666666666%;
  }
}
@media (max-width: 480px){
  .webkit-intro-container{
    margin-bottom: 8.333333333333332%;
  }
}
.webkit-intro-container.first-block {
  margin-top: -152px;
}
@media (max-width: 993px) {
  .webkit-intro-container.first-block {
    margin-top: 0;
  }
}
.webkit-intro-container .webkit-intro-bg {
  padding-top: 85vh;
  background-size: cover;
  background-position: center;
  grid-column-start: 1;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .webkit-intro-container .webkit-intro-bg{
    grid-column-end: 25;
  }
}
.webkit-intro-container .webkit-intro-video-container{
  position: relative;
  overflow: hidden;
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  z-index: 12;
  width: auto;
  height: 85vh;
}
.webkit-intro-container .webkit-intro-video{
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 12;
  min-width: 178vh;
  width: 100%;
  min-height: 85vh;
}
.webkit-intro-container .webkit-intro{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 13;
}
.webkit-intro-container .webkit-intro .webkit-intro-content{
  grid-column-start: 3;
  grid-column-end: 21;
}
@media (max-width: 768px){
  .webkit-intro-container .webkit-intro .webkit-intro-content{
    grid-column-start: 2;
  }
  .webkit-intro-container .webkit-intro .webkit-intro-content{
    grid-column-end: 24;
  }
}
.webkit-intro-container .webkit-intro .webkit-intro-content {
  z-index: 13;
}
.webkit-intro-container .webkit-intro .intro-subnav{
  position: absolute;
  margin-top: 2rem;
  bottom: 0;
}
.webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-title{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 110%;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (max-width: 993px){
  .webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-title{
    margin-bottom: 0px;
  }
}
.webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-links{
  display: flex;
  gap: 4rem;
  margin-bottom: 2rem;
}
@media (max-width: 993px){
  .webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-links{
    margin-bottom: 1rem;
  }
}
.webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-link{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 110%;
  padding-bottom: 0.25rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
.webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-link.active{
  --tw-text-opacity: 1;
  color: rgb(200 72 29 / var(--tw-text-opacity));
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(200 72 29 / var(--tw-border-opacity));
  border-bottom-width: 3px;
}
@media (min-width: 1200px) {
  .webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-link:hover{
    --tw-text-opacity: 1;
    color: rgb(200 72 29 / var(--tw-text-opacity));
  }
  .webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-link:hover{
    border-style: dotted;
  }
  .webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-link:hover{
    --tw-border-opacity: 1;
    border-color: rgb(200 72 29 / var(--tw-border-opacity));
  }
  .webkit-intro-container .webkit-intro .intro-subnav .intro-subnav-link:hover {
    border-bottom-width: 3px;
  }
}
.webkit-intro-container .webkit-intro .webkit-intro-block{
  position: absolute;
  bottom: 50%;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
  transform: translate(0, 50%);
  padding-right: 50%;
}
@media (max-width: 1200px) {
  .webkit-intro-container .webkit-intro .webkit-intro-block {
    padding-right: 40%;
  }
}
@media (max-width: 993px) {
  .webkit-intro-container .webkit-intro .webkit-intro-block {
    padding-right: 20%;
  }
}
@media (max-width: 768px) {
  .webkit-intro-container .webkit-intro .webkit-intro-block {
    padding-right: 10%;
  }
}
@media (max-width: 480px) {
  .webkit-intro-container .webkit-intro .webkit-intro-block {
    padding-right: 5%;
    transform: translate(0, 60%);
  }
}
.webkit-intro-container .webkit-intro .webkit-intro-block .webkit-intro-lead{
  margin-bottom: 2rem;
  font-size: 1rem;
}
.webkit-intro-container .webkit-intro .webkit-intro-block .webkit-intro-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 110%;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
@media (max-width: 768px){
  .webkit-intro-container .webkit-intro .webkit-intro-block .webkit-intro-heading{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2.75rem;
    line-height: 110%;
  }
}
.webkit-intro-container .webkit-intro .webkit-intro-block .webkit-intro-heading {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
  line-height: 4rem;
}
.webkit-intro-container .webkit-intro .webkit-intro-block .webkit-intro-button{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}

.intro-filter{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 85vh;
  z-index: 12;
  background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), black);
  background-color: rgba(0, 0, 0, 0.3);
}
@media (max-width: 993px) {
  .intro-filter {
    background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), black);
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.intro-subnav-fixed{
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  z-index: 11;
}
.intro-subnav-fixed .intro-subnav{
  grid-column-start: 3;
  grid-column-end: 21;
  margin-top: 1.5rem;
}
@media (max-width: 768px){
  .intro-subnav-fixed .intro-subnav{
    grid-column-start: 2;
  }
  .intro-subnav-fixed .intro-subnav{
    grid-column-end: 24;
  }
}
.intro-subnav-fixed .intro-subnav .intro-subnav-title{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 110%;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.intro-subnav-fixed .intro-subnav .intro-subnav-links{
  display: flex;
  gap: 4rem;
  margin-bottom: 1.5rem;
}
.intro-subnav-fixed .intro-subnav .intro-subnav-link{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
  padding-bottom: 0.25rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.intro-subnav-fixed .intro-subnav .intro-subnav-link.active{
  --tw-text-opacity: 1;
  color: rgb(200 72 29 / var(--tw-text-opacity));
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(200 72 29 / var(--tw-border-opacity));
  border-bottom-width: 3px;
}
@media (min-width: 1200px) {
  .intro-subnav-fixed .intro-subnav .intro-subnav-link:hover{
    --tw-text-opacity: 1;
    color: rgb(200 72 29 / var(--tw-text-opacity));
  }
  .intro-subnav-fixed .intro-subnav .intro-subnav-link:hover{
    border-style: dotted;
  }
  .intro-subnav-fixed .intro-subnav .intro-subnav-link:hover{
    --tw-border-opacity: 1;
    border-color: rgb(200 72 29 / var(--tw-border-opacity));
  }
  .intro-subnav-fixed .intro-subnav .intro-subnav-link:hover {
    border-bottom-width: 3px;
  }
}
.intro-subnav-fixed .intro-subnav .webkit-intro-block{
  position: absolute;
  bottom: 33.333333%;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
  padding-right: 60%;
}
@media (max-width: 993px) {
  .intro-subnav-fixed .intro-subnav .webkit-intro-block {
    padding-right: 20%;
  }
}
@media (max-width: 768px) {
  .intro-subnav-fixed .intro-subnav .webkit-intro-block {
    padding-right: 10%;
  }
}
.intro-subnav-fixed .intro-subnav .webkit-intro-block .webkit-intro-lead{
  margin-bottom: 2rem;
  font-size: 1rem;
}
.intro-subnav-fixed .intro-subnav .webkit-intro-block .webkit-intro-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 110%;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
@media (max-width: 768px){
  .intro-subnav-fixed .intro-subnav .webkit-intro-block .webkit-intro-heading{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2.75rem;
    line-height: 110%;
  }
}
.intro-subnav-fixed .intro-subnav .webkit-intro-block .webkit-intro-heading {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
  line-height: 4rem;
}
.intro-subnav-fixed .intro-subnav .webkit-intro-block .webkit-intro-button{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}

.block-zweierblock{
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .block-zweierblock{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .block-zweierblock{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.block-zweierblock {
  display: grid !important;
}
@media (max-width: 768px){
  .block-zweierblock.zweierblock-has-imgblk > .zweierblock-bild{
    grid-row-start: 1;
  }
  .block-zweierblock.zweierblock-has-imgblk > .zweierblock-bild {
    padding-top: 75%;
  }
  .block-zweierblock.zweierblock-has-imgblk > :not(.zweierblock-bild){
    grid-row-start: 2;
  }
}
.zweierblock-1{
  grid-column-start: 3;
  grid-column-end: 12;
  grid-row-start: 1;
}
@media (max-width: 768px){
  .zweierblock-1{
    grid-column-start: 2;
  }
  .zweierblock-1{
    grid-column-end: 24;
  }
}

.zweierblock-2{
  grid-column-start: 13;
  grid-column-end: 23;
  grid-row-start: 1;
}

@media (max-width: 768px){
  .zweierblock-2{
    grid-column-start: 2;
  }
  .zweierblock-2{
    grid-column-end: 24;
  }
  .zweierblock-2{
    grid-row-start: 2;
  }
}

.zweierblock-titelTextLink{
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-self: center;
}
.zweierblock-titelTextLink .zweierblock-subtitle{
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
}
.zweierblock-titelTextLink .zweierblock-heading{
  font-family: Cuprum, sans-serif;
  font-size: 2.5rem;
  line-height: 110%;
  margin-bottom: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 768px){
  .zweierblock-titelTextLink .zweierblock-heading{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }
}
.zweierblock-titelTextLink .zweierblock-heading {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.zweierblock-titelTextLink .zweierblock-lead{
  margin-bottom: 2rem;
  font-size: 1rem;
}
.zweierblock-titelTextLink .zweierblock-button{
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(200 72 29 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(200 72 29 / var(--tw-text-opacity));
}
@media (min-width: 1200px) {
  .zweierblock-titelTextLink .zweierblock-button:hover i{
    margin-left: 0.5rem;
  }
}
.zweierblock-titelTextLink .zweierblock-button i {
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}
.zweierblock-titelTextLink .zweierblock-date{
  margin-bottom: 2rem;
  font-size: .9rem;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .zweierblock-titelTextLink.zweierblock-1{
    grid-column-end: 12;
  }
  .zweierblock-titelTextLink.zweierblock-2{
    grid-column-start: 14;
  }
}

.zweierblock-titel.zweierblock-1{
  font-size: 2.5rem;
  position: relative;
  text-align: start;
}
.zweierblock-titel.zweierblock-1 .zweierblock-titel-titel{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 110%;
  position: relative;
  top: 50%;
  padding: 0.5rem;
  text-transform: uppercase;
}
@media (max-width: 768px){
  .zweierblock-titel.zweierblock-1 .zweierblock-titel-titel{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }
  .zweierblock-titel.zweierblock-1 .zweierblock-titel-titel{
    padding: 0px;
  }
}
.zweierblock-titel.zweierblock-1 .zweierblock-titel-titel {
  transform: translate(0, -50%);
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.zweierblock-titel.zweierblock-2{
  font-size: 2.5rem;
  position: relative;
  text-align: end;
}
.zweierblock-titel.zweierblock-2 .zweierblock-titel-titel{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 110%;
  position: relative;
  top: 50%;
  padding: 0.5rem;
  text-transform: uppercase;
}
@media (max-width: 768px){
  .zweierblock-titel.zweierblock-2 .zweierblock-titel-titel{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }
  .zweierblock-titel.zweierblock-2 .zweierblock-titel-titel{
    padding: 0px;
  }
}
.zweierblock-titel.zweierblock-2 .zweierblock-titel-titel {
  transform: translate(0, -50%);
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.zweierblock-text{
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.zweierblock-text .zweierblock-text-text{
  position: relative;
  top: 50%;
  font-size: 1rem;
  transform: translate(0, -50%);
}

.zweierblock-bild{
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 56.25%;
}

@media (max-width: 1200px){
  .zweierblock-bild{
    padding-top: 66.66666666666666%;
  }
}

@media (max-width: 480px){
  .zweierblock-bild{
    grid-column-start: 2;
  }
  .zweierblock-bild{
    grid-column-end: 24;
  }
}

.zweierblock-bild {
  align-self: center;
}
.zweierblock-bild .zweierblock-image-date{
  position: absolute;
  left: 50%;
  bottom: 0px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transform: translate(-50%, 50%);
  background-size: 82% auto, 100% auto;
  background-image: url("assets/Wiege_der_Schweiz-Banner-Mitte.svg"), url("assets/Wiege_der_Schweiz-Banner-Fluegel.svg");
}
@media screen and (min-width: 769px) {
  .zweierblock-bild.zweierblock-1{
    grid-column-start: 3;
  }
  .zweierblock-bild.zweierblock-2{
    grid-column-end: 23;
  }
}

.slider4cols-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .slider4cols-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .slider4cols-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}
.slider4cols-container .slider4cols{
  grid-column-start: 3;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .slider4cols-container .slider4cols{
    grid-column-start: 2;
  }
  .slider4cols-container .slider4cols{
    grid-column-end: 24;
  }
}
.slider4cols-container .slider4cols{
  position: relative;
  overflow: hidden;
}
.slider4cols-container .slider4cols .slider4cols-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 110%;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
@media (max-width: 768px){
  .slider4cols-container .slider4cols .slider4cols-heading{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }
}
.slider4cols-container .slider4cols .slider4cols-heading {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.slider4cols-container .slider4cols .slider4cols-lead{
  margin-bottom: 2rem;
  font-size: 1rem;
  width: 58.333333%;
}
@media (max-width: 993px){
  .slider4cols-container .slider4cols .slider4cols-lead{
    width: 100%;
  }
}
.slider4cols-container .slider4cols .slider{
  margin-top: 4rem;
}
.slider4cols-container .slider4cols .slider .slide{
  overflow: hidden;
}
@media (min-width: 1200px) {
  .slider4cols-container .slider4cols .slider .slide:hover .slide-link .slide-content{
    bottom: 3rem;
  }
  @media (max-width: 993px){
    .slider4cols-container .slider4cols .slider .slide:hover .slide-link .slide-content{
      bottom: 2rem;
    }
  }
}
.slider4cols-container .slider4cols .slider .slide .slide-link{
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 150%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}
@media (min-width: 1200px) {
  .slider4cols-container .slider4cols .slider .slide .slide-link:hover .slide-background{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
.slider4cols-container .slider4cols .slider .slide .slide-link .slide-content{
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (max-width: 993px){
  .slider4cols-container .slider4cols .slider .slide .slide-link .slide-content{
    bottom: 1rem;
  }
  .slider4cols-container .slider4cols .slider .slide .slide-link .slide-content{
    left: 1rem;
  }
  .slider4cols-container .slider4cols .slider .slide .slide-link .slide-content{
    right: 1rem;
  }
}
.slider4cols-container .slider4cols .slider .slide .slide-link .slide-content {
  z-index: 2;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}
.slider4cols-container .slider4cols .slider .slide .slide-link .slide-content .slide-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  text-transform: uppercase;
  line-height: 100%;
}
.slider4cols-container .slider4cols .slider .slide .slide-link .slide-content .slide-subtitle{
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
}
.slider4cols-container .slider4cols .slider .slick-slide{
  margin: 0.5rem;
}
.slider4cols-container .slider4cols .slider .slick-list{
  margin: -0.5rem;
}
.slider4cols-container .slider4cols .slick-prev, .slider4cols-container .slider4cols .slick-next{
  position: absolute;
  top: -2.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.slider4cols-container .slider4cols .slick-prev .fa-angle-right::before, .slider4cols-container .slider4cols .slick-prev .fa-angle-left::before, .slider4cols-container .slider4cols .slick-next .fa-angle-right::before, .slider4cols-container .slider4cols .slick-next .fa-angle-left::before{
  font-size: 1.75rem;
}
.slider4cols-container .slider4cols .slick-prev{
  position: absolute;
  right: 2rem;
  top: -2.5rem;
  left: auto;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 9999px;
  color: transparent;
  transform: none;
}
.slider4cols-container .slider4cols .slick-prev::before{
  position: absolute;
  left: 50%;
  top: 50%;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  content: "‹";
  line-height: 1.7rem;
  font-size: 1.7rem;
  transform: translate(-50%, -50%);
}
.slider4cols-container .slider4cols .slick-next{
  position: absolute;
  right: 0.25rem;
  top: -2.5rem;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 9999px;
  color: transparent;
  transform: none;
}
.slider4cols-container .slider4cols .slick-next::before{
  position: absolute;
  left: 50%;
  top: 50%;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  content: "›";
  line-height: 1.7rem;
  font-size: 1.7rem;
  transform: translate(-50%, -50%);
}

.black-body .slider4cols-container .slider4cols .slick-prev::before, .black-body .slider4cols-container .slider4cols .slick-next::before{
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

.slider4colsEvents-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .slider4colsEvents-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .slider4colsEvents-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}
.slider4colsEvents-container .slider4colsEvents{
  grid-column-start: 3;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .slider4colsEvents-container .slider4colsEvents{
    grid-column-start: 2;
  }
  .slider4colsEvents-container .slider4colsEvents{
    grid-column-end: 24;
  }
}
.slider4colsEvents-container .slider4colsEvents .slider4colsEvents-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 110%;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
@media (max-width: 768px){
  .slider4colsEvents-container .slider4colsEvents .slider4colsEvents-heading{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }
}
.slider4colsEvents-container .slider4colsEvents .slider4colsEvents-heading {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.slider4colsEvents-container .slider4colsEvents .slider4colsEvents-lead{
  margin-bottom: 2rem;
  font-size: 1rem;
}
.slider4colsEvents-container .slider4colsEvents .slider{
  margin-top: 4rem;
}
.slider4colsEvents-container .slider4colsEvents .slider .slide{
  overflow: hidden;
}
.slider4colsEvents-container .slider4colsEvents .slider .slide .slide-link{
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}
@media (min-width: 1200px) {
  .slider4colsEvents-container .slider4colsEvents .slider .slide .slide-link:hover .slide-background{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
.slider4colsEvents-container .slider4colsEvents .slider .slide .slide-link .slide-content{
  margin-top: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  z-index: 2;
}
.slider4colsEvents-container .slider4colsEvents .slider .slide .slide-link .slide-content .slide-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 110%;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
.slider4colsEvents-container .slider4colsEvents .slider .slide .slide-link .slide-content .slide-subtitle{
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
}
.slider4colsEvents-container .slider4colsEvents .slider .slide .slide-link .slide-content .slide-date{
  font-size: .9rem;
  font-weight: 700;
}
.slider4colsEvents-container .slider4colsEvents .slider .slide .slide-link .slide-image{
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 100%;
  align-self: center;
}
.slider4colsEvents-container .slider4colsEvents .slider .slide .slide-link .slide-image .slide-image-date{
  position: absolute;
  left: 50%;
  bottom: 0px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transform: translate(-50%, 50%);
  background-size: 82% auto, 100% auto;
  background-image: url("assets/Wiege_der_Schweiz-Banner-Mitte.svg"), url("assets/Wiege_der_Schweiz-Banner-Fluegel.svg");
}
.slider4colsEvents-container .slider4colsEvents .slider .slick-slide{
  margin: 0.5rem;
}
.slider4colsEvents-container .slider4colsEvents .slider .slick-list{
  margin: -0.5rem;
}
.slider4colsEvents-container .slider4colsEvents .slick-prev, .slider4colsEvents-container .slider4colsEvents .slick-next{
  position: absolute;
  top: -2.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.slider4colsEvents-container .slider4colsEvents .slick-prev .fa-angle-right::before, .slider4colsEvents-container .slider4colsEvents .slick-prev .fa-angle-left::before, .slider4colsEvents-container .slider4colsEvents .slick-next .fa-angle-right::before, .slider4colsEvents-container .slider4colsEvents .slick-next .fa-angle-left::before{
  font-size: 1.75rem;
}
.slider4colsEvents-container .slider4colsEvents .slick-prev{
  position: absolute;
  right: 2rem;
  top: -2.5rem;
  left: auto;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 9999px;
  color: transparent;
  transform: none;
}
.slider4colsEvents-container .slider4colsEvents .slick-prev::before{
  position: absolute;
  left: 50%;
  top: 50%;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  content: "‹";
  line-height: 1.7rem;
  font-size: 1.7rem;
  transform: translate(-50%, -50%);
}
.slider4colsEvents-container .slider4colsEvents .slick-next{
  position: absolute;
  right: 0.25rem;
  top: -2.5rem;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 9999px;
  color: transparent;
  transform: none;
}
.slider4colsEvents-container .slider4colsEvents .slick-next::before{
  position: absolute;
  left: 50%;
  top: 50%;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  content: "›";
  line-height: 1.7rem;
  font-size: 1.7rem;
  transform: translate(-50%, -50%);
}

.black-body .slider4colsEvents-container .slider4colsEvents .slick-prev::before, .black-body .slider4colsEvents-container .slider4colsEvents .slick-next::before{
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

.event-grid .slide-link{
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}
@media (min-width: 1200px) {
  .event-grid .slide-link:hover .slide-background{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
.event-grid .slide-link .slide-background{
  overflow: hidden;
}
.event-grid .slide-link .slide-content{
  margin-top: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  z-index: 2;
}
.event-grid .slide-link .slide-content .slide-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 110%;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
.event-grid .slide-link .slide-content .slide-subtitle{
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
}
.event-grid .slide-link .slide-content .slide-date{
  font-size: .9rem;
  font-weight: 700;
}
.event-grid .slide-link .slide-image{
  position: relative;
  padding-top: 100%;
  align-self: center;
}
.event-grid .slide-link .slide-image .slide-image-date{
  position: absolute;
  left: 50%;
  bottom: 0px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transform: translate(-50%, 50%);
  background-size: 82% auto, 100% auto;
  background-image: url("assets/Wiege_der_Schweiz-Banner-Mitte.svg"), url("assets/Wiege_der_Schweiz-Banner-Fluegel.svg");
}

.slider3cols-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .slider3cols-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .slider3cols-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}
.slider3cols-container .slider3cols{
  grid-column-start: 3;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .slider3cols-container .slider3cols{
    grid-column-start: 2;
  }
  .slider3cols-container .slider3cols{
    grid-column-end: 24;
  }
}
.slider3cols-container .slider3cols .slider3cols-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 110%;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
@media (max-width: 768px){
  .slider3cols-container .slider3cols .slider3cols-heading{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }
}
.slider3cols-container .slider3cols .slider3cols-heading {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.slider3cols-container .slider3cols .slider3cols-lead{
  margin-bottom: 3rem;
  font-size: 1rem;
}
.slider3cols-container .slider3cols .slider{
  margin-top: 4rem;
}
.slider3cols-container .slider3cols .slider .slide{
  overflow: hidden;
}
.slider3cols-container .slider3cols .slider .slide .slide-link{
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 150%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}
@media (min-width: 1200px) {
  .slider3cols-container .slider3cols .slider .slide .slide-link:hover .slide-background{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
.slider3cols-container .slider3cols .slider .slide .slide-link .slide-content{
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (max-width: 993px){
  .slider3cols-container .slider3cols .slider .slide .slide-link .slide-content{
    bottom: 1rem;
  }
  .slider3cols-container .slider3cols .slider .slide .slide-link .slide-content{
    left: 1rem;
  }
  .slider3cols-container .slider3cols .slider .slide .slide-link .slide-content{
    right: 1rem;
  }
}
.slider3cols-container .slider3cols .slider .slide .slide-link .slide-content {
  z-index: 2;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}
.slider3cols-container .slider3cols .slider .slide .slide-link .slide-content .slide-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 110%;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
.slider3cols-container .slider3cols .slider .slide .slide-link .slide-content .slide-subtitle{
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
}
@media (min-width: 1200px) {
  .slider3cols-container .slider3cols .slider .slide:hover .slide-content{
    bottom: 3rem;
  }
  @media (max-width: 993px){
    .slider3cols-container .slider3cols .slider .slide:hover .slide-content{
      bottom: 2rem;
    }
  }
}
.slider3cols-container .slider3cols .slider .slick-slide{
  margin: 0.5rem;
}
.slider3cols-container .slider3cols .slider .slick-list{
  margin: -0.5rem;
}
.slider3cols-container .slider3cols .slick-prev, .slider3cols-container .slider3cols .slick-next{
  position: absolute;
  top: -2.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.slider3cols-container .slider3cols .slick-prev .fa-angle-right::before, .slider3cols-container .slider3cols .slick-prev .fa-angle-left::before, .slider3cols-container .slider3cols .slick-next .fa-angle-right::before, .slider3cols-container .slider3cols .slick-next .fa-angle-left::before{
  font-size: 1.25rem;
}
.slider3cols-container .slider3cols .slick-prev{
  position: absolute;
  right: 2rem;
  top: -2.5rem;
  left: auto;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 9999px;
  color: transparent;
  transform: none;
}
.slider3cols-container .slider3cols .slick-prev::before{
  position: absolute;
  left: 50%;
  top: 50%;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  content: "‹";
  line-height: 1.7rem;
  font-size: 1.7rem;
  transform: translate(-50%, -50%);
}
.slider3cols-container .slider3cols .slick-next{
  position: absolute;
  right: 0.25rem;
  top: -2.5rem;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 9999px;
  color: transparent;
  transform: none;
}
.slider3cols-container .slider3cols .slick-next::before{
  position: absolute;
  left: 50%;
  top: 50%;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  content: "›";
  line-height: 1.7rem;
  font-size: 1.7rem;
  transform: translate(-50%, -50%);
}

.black-body .slider3cols-container .slider3cols .slick-prev::before, .black-body .slider3cols-container .slider3cols .slick-next::before{
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

.slide-filter{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.webkit-akkordeon-container{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .webkit-akkordeon-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-akkordeon{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-akkordeon-heading-container{
  display: grid;
  grid-template-columns: repeat(18, 1fr);
}

.webkit-akkordeon-heading{
  grid-column-start: 1;
  grid-column-end: 20;
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 110%;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

@media (max-width: 768px){
  .webkit-akkordeon-heading{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }
}

.webkit-akkordeon-heading {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.webkit-akkordeon-lead{
  grid-column-start: 1;
  grid-column-end: 12;
  margin-bottom: 2rem;
  font-size: 1rem;
}

.webkit-akkordeon-subheading{
  padding-bottom: 1.5rem;
}

.webkit-akkordeon-entry{
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-style: dotted;
  border-top-width: 3px;
}
.webkit-akkordeon-entry:last-child {
  border-bottom-width: 3px;
}
@media (min-width: 1200px) {
  .webkit-akkordeon-entry:hover .webkit-akkordeon-title-text{
    margin-left: 1rem;
  }
}

.black-body .webkit-akkordeon-entry{
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
}

.webkit-akkordeon-title{
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

@media (max-width: 768px){
  .webkit-akkordeon-title{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-title{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.webkit-akkordeon-title{
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
}

.webkit-akkordeon-title-text{
  align-self: center;
  grid-column-start: 1;
  grid-column-end: 17;
  padding-right: 0.5rem;
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 110%;
  text-transform: uppercase;
  margin-left: 0px;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 350ms;
}

.webkit-akkordeon-text-container{
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.webkit-akkordeon-text{
  padding-bottom: 2.25rem;
  grid-column-start: 1;
  grid-column-end: 18;
}

.webkit-akkordeon-block{
  grid-column-start: 3;
  grid-column-end: 23;
}

@media (max-width: 768px){
  .webkit-akkordeon-block{
    grid-column: span 21 / span 21;
  }
  .webkit-akkordeon-block{
    grid-column-start: 2;
  }
}

.webkit-akkordeon-icon-container{
  position: relative;
  align-self: center;
  --tw-bg-opacity: 1;
  background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
  border-radius: 9999px;
  grid-column: span 1 / span 1;
  grid-column-start: 18;
  height: 1.5rem;
  width: 1.5rem;
  left: 50%;
  transform: translate(-50%, 0);
}
.webkit-akkordeon-icon-container i{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 993px) {
  .webkit-akkordeon-icon-container .webkit-btn__small{
    transition-property: none;
  }
}
.webkit-image-banner-container{
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media (max-width: 1200px){
  .webkit-image-banner-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}
@media (max-width: 480px){
  .webkit-image-banner-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}
.webkit-image-banner-container.first-block {
  margin-top: -152px;
  padding-bottom: 2.5rem;
}
@media (max-width: 1200px){
  .webkit-image-banner-container.first-block{
    padding-bottom: 4.166666666666666%;
  }
}
@media (max-width: 480px){
  .webkit-image-banner-container.first-block{
    padding-bottom: 8.333333333333332%;
  }
}
.webkit-image-banner-container.first-block{
  padding-top: 0px;
}
@media (max-width: 1200px){
  .webkit-image-banner-container.first-block{
    padding-top: 0px;
  }
}
@media (max-width: 480px){
  .webkit-image-banner-container.first-block{
    padding-top: 0px;
  }
}
@media (max-width: 993px) {
  .webkit-image-banner-container.first-block {
    margin-top: 0;
  }
}
.webkit-image-banner-container .webkit-image-banner{
  grid-column-start: 1;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .webkit-image-banner-container .webkit-image-banner{
    grid-column-end: 25;
  }
}
.webkit-image-banner-container .webkit-image-banner{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 40%;
}
@media (max-width: 993px) {
  .webkit-image-banner-container .webkit-image-banner {
    padding-top: 50%;
  }
}
@media (max-width: 768px) {
  .webkit-image-banner-container .webkit-image-banner {
    padding-top: 60%;
  }
}
.webkit-image-banner-container .webkit-image-text-banner{
  grid-column-start: 1;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .webkit-image-banner-container .webkit-image-text-banner{
    grid-column-end: 25;
  }
}
.webkit-image-banner-container .webkit-image-text-banner{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15%;
}
@media (max-width: 993px) {
  .webkit-image-banner-container .webkit-image-text-banner {
    padding-top: 40%;
  }
}
@media (max-width: 768px) {
  .webkit-image-banner-container .webkit-image-text-banner{
    padding-left: 4.166666666666666%;
    padding-right: 4.166666666666666%;
  }
}
.webkit-image-banner-container .webkit-image-text-banner .webkit-image-banner-text{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 110%;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (max-width: 768px){
  .webkit-image-banner-container .webkit-image-text-banner .webkit-image-banner-text{
    font-family: Cuprum, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }
}
.webkit-image-banner-container .webkit-image-text-banner .webkit-image-banner-text {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.webkit-text-banner-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .webkit-text-banner-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-text-banner-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}
.webkit-text-banner-container .webkit-text-banner{
  grid-column-start: 3;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .webkit-text-banner-container .webkit-text-banner{
    grid-column-start: 2;
  }
  .webkit-text-banner-container .webkit-text-banner{
    grid-column-end: 24;
  }
}
.webkit-text-banner-container .webkit-text-banner{
  margin-top: 4rem;
}
.webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block{
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 2.5rem;
  background-position: 50% 0%, 50% 82.3%;
  background-size: 82.3% 100%, 100% 80%;
  background-image: url("assets/Wiege_der_Schweiz-Banner-Mitte.png"), url("assets/Wiege_der_Schweiz-Banner-Fluegel.png");
  padding-left: 9%;
  padding-right: 9%;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  position: relative;
  margin-bottom: 5rem;
}
@media (max-width: 1200px) {
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block{
    background-position: bottom;
  }
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block {
    background-size: 82% auto, 100% auto;
    background-image: url("assets/Wiege_der_Schweiz-Banner-Mitte.svg"), url("assets/Wiege_der_Schweiz-Banner-Fluegel.svg");
  }
}
@media (max-width: 993px) {
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block {
    padding-top: 0;
  }
}
.webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container{
  position: relative;
  left: 50%;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-bottom: 1rem;
  padding-top: 3.5rem;
}
@media (max-width: 993px){
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container{
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container{
  text-align: center;
  width: 100%;
  transform: translate(-50%, 0);
}
@media (max-width: 1200px) {
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container{
    padding-top: 1.5rem;
  }
}
@media (max-width: 993px) {
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container{
    width: 100%;
  }
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container {
    background-color: #fee19b;
  }
}
@media (max-width: 768px) {
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container{
    width: 100%;
  }
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container {
    background-color: #fee19b;
  }
}
.webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container .webkit-text-banner-lead{
  font-family: handsome-nib, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}
.webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container .webkit-text-banner-heading{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 110%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-transform: uppercase;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-heading-container .webkit-text-banner-wappen{
  position: absolute;
  left: 50%;
  top: 0px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 2rem;
  width: 2rem;
  transform: translate(-50%, -50%);
  background-image: url("assets/wappen.png");
}
.webkit-text-banner-container .webkit-text-banner .webkit-text-banner-block .webkit-text-banner-button{
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 50%);
}

.first-block .banner-filter{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3), black);
}

.banner-filter{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4), black);
}

.banner-filter-detail{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3), black);
}

.banner-top-filter{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.quiz-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .quiz-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .quiz-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}
.quiz-container .quiz{
  grid-column-start: 3;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .quiz-container .quiz{
    grid-column-start: 2;
  }
  .quiz-container .quiz{
    grid-column-end: 24;
  }
}

.mapContainer{
  cursor: move;
  grid-column-start: 4;
  grid-column-end: 22;
}

@media (max-width: 768px){
  .mapContainer{
    grid-column-start: 3;
  }
  .mapContainer{
    grid-column-end: 23;
  }
}

.mapContainer {
  position: fixed !important;
  position: relative !important;
  height: 800px;
}
@media (max-width: 1200px) {
  .mapContainer {
    height: 600px;
  }
}
@media (max-width: 993px) {
  .mapContainer {
    height: 400px;
  }
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 400px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.bubble-content{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  padding: 20px;
}

.bubble-image{
  background-size: cover;
  background-position: center;
  padding-top: 56.25%;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
/* JavaScript will position this div at the bottom of the popup tip. */
#popup-container{
  border-radius: 0.5rem;
  cursor: auto;
  /* The max width of the info window. */
  width: 300px;
  transform: translate(-50%, -103%);
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  /* Style the bubble. */
  border-radius: 5px;
  font-family: sans-serif;
  max-height: 400px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}
@media (max-width: 768px) {
  #popup-container {
    max-height: 300px;
  }
}

@media (max-width: 993px) {
  .popup-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.row-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media (max-width: 993px){
  .row-container{
    display: grid;
  }
  .row-container{
    grid-template-columns: repeat(1, 1fr);
  }
  .row-container{
    gap: 0px;
  }
}

.row-container{
  max-width: 100%;
}

.row-map{
  position: relative;
}

@media (max-width: 993px){
  .row-list{
    display: none;
  }
}

.row-list {
  height: 800px;
  overflow-y: auto;
}
@media (max-width: 1200px) {
  .row-list {
    height: 600px;
  }
}
@media (max-width: 993px) {
  .row-list {
    height: 400px;
  }
}

.item{
  display: flex;
  gap: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1rem;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-bottom-width: 3px;
  border-style: dotted;
}

.list-image {
  flex-basis: 200px;
  flex-shrink: 0;
  flex-grow: 0;
  height: 200px;
  background-position: center;
  background-size: cover;
}

.map-slider{
  display: none;
}

@media (max-width: 993px){
  .map-slider{
    display: block;
  }
}

.map-slider{
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 75%;
  transform: translate(-50%, 0);
}
.map-slider .map-card{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}
.map-slider .map-card .map-card-content{
  padding: 1rem;
}

.ol-zoom{
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem;
}
.ol-zoom .ol-zoom-in{
  height: 2rem;
  width: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ol-zoom .ol-zoom-out{
  height: 2rem;
  width: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ol-rotate{
  position: absolute;
  right: 0px;
  top: 0px;
  display: none;
  padding: 0.5rem;
}
.ol-rotate .ol-rotate-reset{
  height: 2rem;
  width: 2rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("assets/north.png");
}
.ol-rotate .ol-rotate-reset .ol-compass {
  color: transparent;
}

.ol-scale-line{
  display: none;
}

.close-icon{
  display: none;
}

#popup-container .close-icon{
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: block;
  cursor: pointer;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  height: 1.5rem;
  width: 1.5rem;
}
#popup-container .close-icon i{
  position: absolute;
  top: 0px;
  right: 0px;
  height: 1.5rem;
  width: 1.5rem;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}
#popup-container .close-icon i::before{
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.75rem;
}

.map-button{
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(200 72 29 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(200 72 29 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}
@media (min-width: 1200px) {
  .map-button:hover i{
    margin-left: 0.5rem;
  }
}
.map-button i {
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.api-block {
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}
@media (min-width: 1200px) {
  .api-block:hover .slide-background{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.slide-background{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
}

.card-content{
  position: absolute;
  bottom: 0px;
  z-index: 10;
  padding: 1rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

@media (min-width: 1200px) {
  a:hover .card-content{
    bottom: 0.5rem;
  }
}

.redactor-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .redactor-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .redactor-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}
.redactor-container .redactor{
  grid-column-start: 3;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .redactor-container .redactor{
    grid-column-start: 2;
  }
  .redactor-container .redactor{
    grid-column-end: 24;
  }
}
.webcam-border{
  border-style: dotted;
  border-bottom-width: 3px;
}

.app-header{
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 14;
}

.header-nav{
  float: left;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

.header-container{
  grid-column-start: 1;
  grid-column-end: 23;
}

@media (max-width: 768px){
  .header-container{
    grid-column-end: 25;
  }
}

.header-container{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 110%;
  padding: 1rem;
}
@media (max-width: 993px) {
  .header-container {
    background-image: none;
  }
}
.header-container .links{
  font-size: 1.75rem;
}
@media (max-width: 2000px) {
  .header-container .links{
    font-size: 1.25rem;
  }
}
@media (max-width: 1200px) {
  .header-container .links{
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .header-container .links a {
    transition-property: border-color;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    border-color: rgba(247, 247, 247, 0);
  }
  .header-container .links a:hover{
    --tw-text-opacity: 1;
    color: rgb(247 247 247 / var(--tw-text-opacity));
  }
  .header-container .links a:hover{
    border-style: dotted;
  }
  .header-container .links a:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .header-container .links a:hover {
    border-bottom-width: 3px;
  }
}
.header-container a.active{
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  border-bottom-width: 3px;
}
.header-container.no-intro{
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.header-container.no-intro .links{
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
@media (min-width: 1200px) {
  .header-container.no-intro .links a:hover{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
  .header-container.no-intro .links a:hover{
    border-style: dotted;
  }
  .header-container.no-intro .links a:hover{
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
  }
  .header-container.no-intro .links a:hover {
    border-bottom-width: 3px;
  }
}
.header-container.no-intro a.active{
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-bottom-width: 3px;
}

.white-body{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.black-body{
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

.space-between {
  justify-content: space-between;
}

.app-main {
  margin-top: 152px;
}
@media (max-width: 993px) {
  .app-main {
    margin-top: 0;
  }
}

.has-overlay .app-main .body-blur{
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 40;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.side-bar{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

@media (max-width: 768px){
  .side-bar{
    top: 1rem;
  }
  .side-bar{
    right: 0.5rem;
  }
  .side-bar{
    bottom: auto;
  }
}

.side-bar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 768px){
  .side-bar{
    justify-content: flex-start;
  }
}

.side-bar{
  text-align: center;
}

@media (max-width: 768px){
  .side-bar{
    --tw-bg-opacity: 0;
  }
}

.side-bar {
  width: 8.3333%;
  min-width: 60px;
  align-items: center;
  z-index: 999;
}
.side-bar .side-bar-button, .black-body .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed{
  position: relative;
  font-size: .9rem;
}
@media (max-width: 768px){
  .side-bar .side-bar-button, .black-body .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed{
    font-size: 1rem;
  }
}
.side-bar .side-bar-button, .black-body .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed{
  height: 2.75rem;
  width: 2.75rem;
}
@media (max-width: 768px){
  .side-bar .side-bar-button, .black-body .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed{
    height: 3rem;
  }
  .side-bar .side-bar-button, .black-body .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed{
    width: 3rem;
  }
}
.side-bar .side-bar-button, .black-body .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed-white, .side-bar .side-bar-button__reversed{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .side-bar .side-bar-button:hover, .black-body .side-bar .side-bar-button__reversed-white:hover, .side-bar .side-bar-button__reversed-white:hover, .side-bar .side-bar-button__reversed:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .side-bar .side-bar-button:hover, .black-body .side-bar .side-bar-button__reversed-white:hover, .side-bar .side-bar-button__reversed-white:hover, .side-bar .side-bar-button__reversed:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .side-bar .side-bar-button:hover, .black-body .side-bar .side-bar-button__reversed-white:hover, .side-bar .side-bar-button__reversed-white:hover, .side-bar .side-bar-button__reversed:hover{
    --tw-text-opacity: 1;
    color: rgb(144 17 28 / var(--tw-text-opacity));
  }
}
.side-bar .side-bar-button,
.black-body .side-bar .side-bar-button__reversed-white,
.side-bar .side-bar-button__reversed-white,
.side-bar .side-bar-button__reversed {
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
  line-height: 2.75rem;
}
@media (max-width: 768px) {
  .side-bar .side-bar-button,
.black-body .side-bar .side-bar-button__reversed-white,
.side-bar .side-bar-button__reversed-white,
.side-bar .side-bar-button__reversed {
    line-height: 3rem;
  }
}
.side-bar .side-bar-button__reversed{
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0;
  --tw-text-opacity: 1;
  color: rgb(144 17 28 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .side-bar .side-bar-button__reversed:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .side-bar .side-bar-button__reversed:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  }
  .side-bar .side-bar-button__reversed:hover{
    --tw-text-opacity: 1;
    color: rgb(247 247 247 / var(--tw-text-opacity));
  }
}
.side-bar .side-bar-button__reversed-white{
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .side-bar .side-bar-button__reversed-white:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .side-bar .side-bar-button__reversed-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .side-bar .side-bar-button__reversed-white:hover{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}
.side-bar .side-bar-button i,
.black-body .side-bar .side-bar-button__reversed-white i,
.side-bar .side-bar-button__reversed i,
.side-bar .side-bar-button__reversed-white i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px){
  .side-bar .nav-lang{
    display: none;
  }
}

.black-body .side-bar{
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

@media (max-width: 768px){
  .black-body .side-bar{
    --tw-bg-opacity: 0;
  }
}
@media (min-width: 1200px){
  .black-body .side-bar .side-bar-button:hover, .black-body .side-bar .side-bar-button__reversed-white:hover, .black-body .side-bar .side-bar-button__reversed:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .black-body .side-bar .side-bar-button:hover, .black-body .side-bar .side-bar-button__reversed-white:hover, .black-body .side-bar .side-bar-button__reversed:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
  .black-body .side-bar .side-bar-button:hover, .black-body .side-bar .side-bar-button__reversed-white:hover, .black-body .side-bar .side-bar-button__reversed:hover{
    --tw-text-opacity: 1;
    color: rgb(247 247 247 / var(--tw-text-opacity));
  }
}
.black-body .side-bar .side-bar-button__reversed-white{
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .black-body .side-bar .side-bar-button__reversed-white:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .black-body .side-bar .side-bar-button__reversed-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  }
  .black-body .side-bar .side-bar-button__reversed-white:hover{
    --tw-text-opacity: 1;
    color: rgb(247 247 247 / var(--tw-text-opacity));
  }
}

.search-outer-grid{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1200px) {
  .sidebar-links a {
    transition-property: border-color;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    border-color: rgba(0, 0, 0, 0);
  }
  .sidebar-links a:hover{
    border-style: dotted;
  }
  .sidebar-links a:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .sidebar-links a:hover {
    border-bottom-width: 3px;
  }
}
.sidebar-links a.active{
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-bottom-width: 3px;
}

.overlay{
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  right: -100%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}
.overlay.overlay-show {
  pointer-events: all;
  overflow-y: auto;
  z-index: 10000;
  visibility: visible;
  opacity: 1;
  right: 0%;
}
.overlay .overlay-inner {
  padding: 3rem;
}

.overlay-info{
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  position: fixed;
  top: 0px;
  bottom: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  width: 50rem;
  max-width: 75%;
  right: -100%;
}
@media (max-width: 768px) {
  .overlay-info {
    max-width: 100%;
  }
}
.overlay-info .side-bar{
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
@media (max-width: 993px){
  .overlay-info .side-bar .nav-lang{
    display: block;
  }
}
.overlay-info .side-bar .nav-lang{
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

.overlay-menu{
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  position: fixed;
  top: 0px;
  bottom: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  width: 50rem;
  max-width: 75%;
  right: -100%;
}
@media (max-width: 768px) {
  .overlay-menu {
    max-width: 100%;
  }
}
.overlay-menu .side-bar{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}
@media (max-width: 993px) {
  .overlay-menu .side-bar {
    background-color: transparent;
    transition-property: height;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
  }
  .overlay-menu .side-bar .nav-lang{
    display: block;
  }
}
@media (max-width: 768px) {
  .overlay-menu .overlay-inner {
    padding-right: 4rem;
  }
}
.overlay-menu.overlay-show {
  right: 0%;
}

.menu-title{
  margin-bottom: 0.5rem;
  font-size: .725rem;
  font-weight: 700;
}

.menu-nav-link{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.language-dropdown{
  position: absolute;
}

footer{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-template-columns: repeat(24, 1fr);
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  padding-top: 3rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  overflow: hidden;
}

.black-body footer{
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
.black-body footer .sub-footer{
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
}
.black-body footer .footer-content-cell{
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
}

.footer-address p{
  font-size: .9rem;
}
.footer-address strong{
  font-size: .9rem;
  font-weight: 900;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.footer-contact-title{
  font-size: .9rem;
  font-weight: 900;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.black-body .footer-title{
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

.footer-title{
  margin-bottom: 1rem;
  font-size: .9rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
@media (max-width: 1200px) {
  .footer-title{
    font-size: .9rem;
  }
}

.footer-navigation{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.footer-navigation-menu{
  font-family: Cuprum, sans-serif;
  font-size: 1.25rem;
  line-height: 110%;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .footer-navigation-menu a {
    transition-property: border-color;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    border-color: rgba(0, 0, 0, 0);
  }
  .footer-navigation-menu a:hover{
    border-style: dotted;
  }
  .footer-navigation-menu a:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .footer-navigation-menu a:hover {
    border-bottom-width: 3px;
  }
}
.footer-navigation-menu a.active{
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-bottom-width: 3px;
}

.footer-nav-link{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.footer-contact-cell{
  grid-column: span 5 / span 5;
  grid-column-start: 3;
  padding-bottom: 3rem;
}

@media (max-width: 1200px){
  .footer-contact-cell{
    grid-column: span 5 / span 5;
  }
  .footer-contact-cell{
    grid-column-start: 3;
  }
}

@media (max-width: 993px){
  .footer-contact-cell{
    grid-column: span 10 / span 10;
  }
  .footer-contact-cell{
    grid-column-start: 3;
  }
  .footer-contact-cell{
    grid-row-start: 1;
  }
}

@media (max-width: 768px){
  .footer-contact-cell{
    grid-column: span 20 / span 20;
  }
  .footer-contact-cell{
    grid-column-start: 3;
  }
}

.footer-contact-btn{
  border-radius: 0.375rem;
}

.footer-newsletter-cell{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.footer-navigation-cell{
  grid-column: span 6 / span 6;
}

@media (max-width: 1200px){
  .footer-navigation-cell{
    grid-column: span 6 / span 6;
  }
}

@media (max-width: 993px){
  .footer-navigation-cell{
    grid-column: span 12 / span 12;
  }
  .footer-navigation-cell{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (max-width: 768px){
  .footer-navigation-cell{
    grid-column: span 24 / span 24;
  }
}

.sub-footer{
  grid-column-start: 3;
  grid-column-end: 23;
  grid-row-start: 2;
  justify-self: center;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
}

@media (max-width: 768px){
  .sub-footer{
    grid-column-start: 2;
  }
  .sub-footer{
    grid-column-end: 24;
  }
}

.sub-footer{
  width: 100%;
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-top-width: 3px;
}

.sub-footer-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 1rem;
}

@media (max-width: 768px){
  .sub-footer-container{
    justify-content: flex-start;
  }
}

.sub-footer-container {
  justify-content: space-between;
}

.sub-footer-link{
  padding-right: 1rem;
  font-size: .9rem;
  font-weight: 700;
}

.sub-footer-copyright{
  grid-column: span 19 / span 19;
  grid-column-start: 4;
  grid-row-start: 3;
  justify-self: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

@media (max-width: 993px){
  .sub-footer-copyright{
    grid-row-start: 4;
  }
}

@media (max-width: 768px){
  .sub-footer-copyright{
    grid-row-start: 5;
  }
}

.footer-content-cell{
  grid-column-start: 3;
  grid-column-end: 23;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (max-width: 768px){
  .footer-content-cell{
    grid-column-start: 2;
  }
  .footer-content-cell{
    grid-column-end: 24;
  }
}

.footer-content-cell{
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-top-width: 3px;
}

.webkit-social-media-icons{
  position: relative;
  bottom: 0px;
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 1200px){
  .webkit-social-media-icons:hover{
    bottom: 0.25rem;
  }
}

.webkit-social-media-icons {
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.footer-link{
  margin-bottom: 0.5rem;
  display: block;
  font-size: .9rem;
  font-weight: 700;
}
.footer-link .footer-link-marker{
  position: relative;
  margin-right: 0.5rem;
  display: inline;
  height: 1.75rem;
  width: 1.75rem;
  bottom: 0px;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}
@media (min-width: 1200px) {
  .footer-link:hover .footer-link-marker{
    bottom: 0.25rem;
  }
}

.btn{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(144 17 28 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .btn:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .btn:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  }
  .btn:hover{
    --tw-text-opacity: 1;
    color: rgb(247 247 247 / var(--tw-text-opacity));
  }
}

.btn{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.btn-small{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgb(144 17 28 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .btn-small:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .btn-small:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  }
  .btn-small:hover{
    --tw-text-opacity: 1;
    color: rgb(247 247 247 / var(--tw-text-opacity));
  }
}

.btn-small{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.btn-filled{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .btn-filled:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .btn-filled:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .btn-filled:hover{
    --tw-text-opacity: 1;
    color: rgb(144 17 28 / var(--tw-text-opacity));
  }
}

.btn-filled{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.btn-filled-small{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .btn-filled-small:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .btn-filled-small:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .btn-filled-small:hover{
    --tw-text-opacity: 1;
    color: rgb(144 17 28 / var(--tw-text-opacity));
  }
}

.btn-filled-small{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.btn-black{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}

.btn-black-small{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}

.btn-white{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .btn-white:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .btn-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .btn-white:hover{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}

.btn-white{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.btn-white-small{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}

.black-body .btn{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .black-body .btn:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .black-body .btn:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .black-body .btn:hover{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}

.black-body .btn{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}
.black-body .btn-small{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .black-body .btn-small:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .black-body .btn-small:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .black-body .btn-small:hover{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}
.black-body .btn-small{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}
.black-body .btn-black{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .black-body .btn-black:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .black-body .btn-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .black-body .btn-black:hover{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}
.black-body .btn-black{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}
.black-body .btn-filled{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .black-body .btn-filled:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .black-body .btn-filled:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .black-body .btn-filled:hover{
    --tw-text-opacity: 1;
    color: rgb(144 17 28 / var(--tw-text-opacity));
  }
}
.black-body .btn-filled{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}
.black-body .btn-black-small{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .black-body .btn-black-small:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .black-body .btn-black-small:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .black-body .btn-black-small:hover{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}
.black-body .btn-black-small{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}
.black-body .btn-filled-small{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .black-body .btn-filled-small:hover{
    --tw-border-opacity: 1;
    border-color: rgb(247 247 247 / var(--tw-border-opacity));
  }
  .black-body .btn-filled-small:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .black-body .btn-filled-small:hover{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}
.black-body .btn-filled-small{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}

.webkit-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-template-columns: repeat(24, 1fr);
  margin-bottom: 2rem;
}

.webkit-content{
  grid-column-start: 3;
  grid-column-end: 17;
}

@media (max-width: 993px){
  .webkit-content{
    grid-column: span 21 / span 21;
  }
  .webkit-content{
    grid-column-start: 2;
  }
}

@media (max-width: 768px){
  .webkit-content{
    grid-column: span 22 / span 22;
  }
  .webkit-content{
    grid-column-start: 2;
  }
}

.sidescroll{
  grid-column-start: 17;
  grid-column-end: 23;
}

@media (max-width: 993px){
  .sidescroll{
    grid-column: span 21 / span 21;
  }
  .sidescroll{
    grid-column-start: 2;
  }
}

@media (max-width: 768px){
  .sidescroll{
    grid-column: span 22 / span 22;
  }
  .sidescroll{
    grid-column-start: 2;
  }
}

.sidescroll{
  margin-left: 2rem;
  margin-top: 2rem;
}

@media (max-width: 993px){
  .sidescroll{
    margin-left: 0px;
  }
}
.sidescroll .scroll{
  position: sticky;
  top: 2rem;
  padding-bottom: 1.25rem;
}
@media (max-width: 993px){
  .sidescroll .scroll{
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
@media (max-width: 768px){
  .sidescroll .scroll{
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.sidescroll .scroll {
  padding-left: 9%;
  padding-right: 9%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 82% auto, 100% auto;
  background-image: url("assets/Wiege_der_Schweiz-Banner-Mitte.svg"), url("assets/Wiege_der_Schweiz-Banner-Fluegel.svg");
}
@media (max-width: 993px) {
  .sidescroll .scroll {
    width: 456px;
    max-width: 90%;
  }
  .sidescroll .scroll{
    margin-left: auto;
    margin-right: auto;
  }
}
.sidescroll .scroll .scroll-content{
  margin-bottom: 2rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-top: 3.5rem;
  background-color: #fee19b;
}
.sidescroll .scroll .scroll-content .scroll-title {
  line-height: 1.75rem;
}
.sidescroll .scroll-tour{
  position: sticky;
  top: 2rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-bottom: 1.25rem;
  text-align: center;
}
@media (max-width: 993px){
  .sidescroll .scroll-tour{
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
@media (max-width: 768px){
  .sidescroll .scroll-tour{
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.sidescroll .scroll-tour {
  padding-left: 9%;
  padding-right: 9%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 82% auto, 100% auto;
  background-image: url("assets/Wiege_der_Schweiz-Banner-Mitte.svg"), url("assets/Wiege_der_Schweiz-Banner-Fluegel.svg");
}
@media (max-width: 993px) {
  .sidescroll .scroll-tour {
    width: 456px;
    max-width: 90%;
  }
  .sidescroll .scroll-tour{
    margin-left: auto;
    margin-right: auto;
  }
}
.sidescroll .scroll-tour .scroll-content{
  margin-bottom: 2rem;
  padding-top: 3.5rem;
  background-color: #fee19b;
}

.breadcrumbs-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 2000px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2.5rem;
}

@media (max-width: 1200px){
  .breadcrumbs-container{
    padding-top: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .breadcrumbs-container{
    padding-top: 0.5rem;
  }
}
.breadcrumbs-container.first-block {
  margin-top: 152px;
  padding-top: 0px;
}
@media (max-width: 1200px){
  .breadcrumbs-container.first-block{
    padding-top: 0px;
  }
}
@media (max-width: 480px){
  .breadcrumbs-container.first-block{
    padding-top: 0px;
  }
}
@media (max-width: 993px) {
  .breadcrumbs-container.first-block {
    margin-top: 152px;
  }
}
.breadcrumbs-container.first-block .breadcrumbs{
  margin-top: 2rem;
}
@media (max-width: 768px){
  .breadcrumbs-container.first-block .breadcrumbs{
    margin-top: 1.5rem;
  }
}
.breadcrumbs-container .breadcrumbs{
  grid-column-start: 3;
  grid-column-end: 23;
}
@media (max-width: 768px){
  .breadcrumbs-container .breadcrumbs{
    grid-column-start: 2;
  }
  .breadcrumbs-container .breadcrumbs{
    grid-column-end: 24;
  }
}
.breadcrumbs-container .breadcrumbs{
  margin-bottom: 2rem;
  font-size: .9rem;
}
@media (max-width: 768px){
  .breadcrumbs-container .breadcrumbs{
    margin-bottom: 0px;
  }
}
.breadcrumbs-container .breadcrumbs i{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: .725rem;
}

.breadcrumbs{
  margin-bottom: 2rem;
  font-size: .9rem;
}

@media (max-width: 768px){
  .breadcrumbs{
    margin-bottom: 1.5rem;
  }
}
.breadcrumbs i{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: .725rem;
}

.suitable{
  --tw-bg-opacity: 1;
  background-color: rgb(192 204 86 / var(--tw-bg-opacity));
}

.filter-container{
  text-align: left;
  flex-wrap: wrap;
}
.filter-container i{
  position: absolute;
  right: 0.25rem;
  top: 0px;
  height: 100%;
  width: 1.5rem;
}
.filter-container i::before{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tour-map{
  position: relative;
}
.tour-map .mapContainer{
  cursor: move;
  position: relative !important;
  height: 800px;
}
@media (max-width: 1600px) {
  .tour-map .mapContainer {
    height: 600px;
  }
}
@media (max-width: 993px) {
  .tour-map .mapContainer {
    height: 400px;
  }
}

.elevation-hover-box{
  position: absolute;
  z-index: 50;
  left: 5.6%;
  right: 2%;
  top: 12px;
  bottom: 11%;
}

.center-button{
  position: absolute;
  left: 50%;
  display: inline-block;
  cursor: pointer;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .center-button:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .center-button:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .center-button:hover{
    --tw-text-opacity: 1;
    color: rgb(144 17 28 / var(--tw-text-opacity));
  }
}

.center-button{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.black-body .center-button{
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(144 17 28 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(144 17 28 / var(--tw-bg-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .black-body .center-button:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
  .black-body .center-button:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  }
  .black-body .center-button:hover{
    --tw-text-opacity: 1;
    color: rgb(144 17 28 / var(--tw-text-opacity));
  }
}

.black-body .center-button{
  font-family: Cuprum, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 110%;
}

.center-button {
  transform: translate(-50%, 0);
}

.scroll-top-icon {
  right: -60px;
  position: fixed;
  z-index: 1000;
  cursor: pointer;
  bottom: 3rem;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 350ms;
  width: 60px;
  height: 120px;
}
.scroll-top-icon.showing {
  right: 1.5rem;
}
@media (max-width: 993px) {
  .scroll-top-icon.showing {
    right: 1rem;
  }
}
.scroll-top-icon::before, .scroll-top-icon::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 350ms;
}
.scroll-top-icon::before {
  background-image: url("./assets/scrolltop.svg");
  opacity: 1;
}
.scroll-top-icon::after {
  background-image: url("./assets/scrolltop_hover.svg");
  opacity: 0;
}
@media (min-width: 1200px) {
  .scroll-top-icon:hover::before {
    opacity: 0;
  }
  .scroll-top-icon:hover::after {
    opacity: 1;
  }
  .scroll-top-icon.scrolling-up::before {
    opacity: 0;
  }
  .scroll-top-icon.scrolling-up::after {
    opacity: 1;
  }
}
@media (max-width: 993px) {
  .scroll-top-icon {
    bottom: 1rem;
  }
}

.redactor-text{
  font-size: 1rem;
}
.redactor-text h1{
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.redactor-text h2{
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.redactor-text h3{
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.redactor-text h4{
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.redactor-text h5{
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.redactor-text p{
  font-size: 1rem;
  margin-bottom: 2rem;
}
.redactor-text em{
  font-size: 1rem;
}
.redactor-text strong{
  font-size: 1rem;
  font-weight: 700;
}
.redactor-text p:first-child strong{
  padding-top: 0px;
}
.redactor-text ul, .redactor-text ol{
  font-size: 1rem;
}
.redactor-text a{
  font-size: 1rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(144 17 28 / var(--tw-text-opacity));
  display: inline-block;
}
.redactor-text a:after {
  content: "";
  border-bottom-width: 2px;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
}
.redactor-text p + p > strong {
  padding: 0;
}
.redactor-text p + p,
.redactor-text p + ul {
  margin-top: 0.5em;
}
.redactor-text ul + p{
  margin-top: 0.625rem;
}
.redactor-text ul {
  list-style: none;
}
.redactor-text ul > li {
  position: relative;
  display: block;
  overflow: hidden;
  margin-left: 0;
  padding-left: 1rem;
  text-indent: -0.5rem;
}
.redactor-text ul > li:before {
  content: "";
  transform: translateX(-100%);
  display: inline-block;
  margin-bottom: 0.125em;
  vertical-align: baseline;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(200 72 29 / var(--tw-border-opacity));
}
.redactor-text ul > li > a {
  text-indent: 0;
}
.redactor-text ol {
  list-style: decimal;
  padding: 1em 0 0;
}

.detail-text{
  font-size: 1rem;
}
.detail-text h1{
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.detail-text h2{
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.detail-text h3{
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.detail-text h4{
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.detail-text h5{
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.detail-text p{
  font-size: 1rem;
  margin-bottom: 2rem;
}
.detail-text em{
  font-size: 1rem;
}
.detail-text strong{
  font-size: 1rem;
  font-weight: 700;
}
.detail-text p:first-child strong{
  padding-top: 0px;
}
.detail-text ul, .detail-text ol{
  font-size: 1rem;
}
.detail-text a{
  font-size: 1rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(144 17 28 / var(--tw-text-opacity));
  display: inline-block;
}
.detail-text a:after {
  content: "";
  border-bottom-width: 2px;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-border-opacity));
}
.detail-text p + p > strong {
  padding: 0;
}
.detail-text p + p,
.detail-text p + ul {
  margin-top: 0.5em;
}
.detail-text ul + p{
  margin-top: 0.625rem;
}
.detail-text ul {
  list-style: none;
}
.detail-text ul > li {
  position: relative;
  display: block;
  overflow: hidden;
  margin-left: 0;
  padding-left: 1rem;
  text-indent: -0.5rem;
}
.detail-text ul > li:before {
  content: "";
  transform: translateX(-100%);
  display: inline-block;
  margin-bottom: 0.125em;
  vertical-align: baseline;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(200 72 29 / var(--tw-border-opacity));
}
.detail-text ul > li > a {
  text-indent: 0;
}
.detail-text ol {
  list-style: decimal;
  padding: 1em 0 0;
}

.search-shadow-container{
  height: max-content;
  width: max-content;
  overflow: hidden;
}

@media (max-width: 768px){
  .search-shadow-container{
    width: 91.666667%;
  }
}

@media (max-width: 480px){
  .search-shadow-container{
    width: 100%;
  }
}

.search-shadow-container{
  margin-left: auto;
  margin-right: auto;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1.5rem;
}

.search-standby-container{
  margin-left: auto;
  margin-right: auto;
  border-radius: 9999px;
  height: max-content;
  width: max-content;
}

@media (max-width: 768px){
  .search-standby-container{
    width: 91.666667%;
  }
}

@media (max-width: 480px){
  .search-standby-container{
    width: 100%;
  }
}
.search-standby-container .search-container{
  border-radius: 9999px;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.autocomplete-container,
.search-container {
  width: 30rem;
}

@media (max-width: 768px){
  .autocomplete-container, .search-container{
    width: 100%;
  }
}

.search-autocomplete-item{
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: .9rem;
}

@media (min-width: 1200px){
  .search-autocomplete-item:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
  .search-autocomplete-item:hover{
    --tw-bg-opacity: 0.1;
  }
}
.search-autocomplete-item.active-link{
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
}
.search-autocomplete-item:last-child{
  padding-bottom: 1rem;
}

.search-container{
  height: 4rem;
  border-radius: 9999px;
}
.search-container .search-input{
  border-style: none;
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
  height: 4rem;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  padding-left: 1.5rem;
  width: calc(100% - 3.5rem);
}
.search-container .search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1;
  /* Firefox */
}
.search-container .search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}
.search-container .search-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}
.search-container .btn-search{
  width: 2rem;
}

.fake-loading img {
  animation: spinner 0.4s infinite linear;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.addthis-container {
  display: block;
  margin-bottom: 4rem;
  --tw-text-opacity: 1 !important;
  color: rgb(247 247 247 / var(--tw-text-opacity)) !important;
}
.addthis-container .addthis_inline_share_toolbox a{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(144 17 28 / var(--tw-bg-opacity)) !important;
  border-radius: 666 !important;
  margin: 0 5px !important;
}
@media (max-width: 768px) {
  .addthis-container .addthis_inline_share_toolbox a {
    margin: 0 3px !important;
  }
}
.addthis-container .addthis_inline_share_toolbox a .at-icon-wrapper {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
}
.addthis-container .addthis_inline_share_toolbox a .at-icon-wrapper svg {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
}
@media (max-width: 768px) {
  .addthis-container .addthis_inline_share_toolbox a .at-icon-wrapper {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
  }
  .addthis-container .addthis_inline_share_toolbox a .at-icon-wrapper svg {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
  }
}

@media (min-width: 1200px) {
  a {
    transition-property: color, border-color;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
  }
  a:hover{
    --tw-text-opacity: 1;
    color: rgb(144 17 28 / var(--tw-text-opacity));
  }
  a:hover{
    --tw-border-opacity: 1;
    border-color: rgb(144 17 28 / var(--tw-border-opacity));
  }
}
.not-found-link{
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(200 72 29 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(200 72 29 / var(--tw-text-opacity));
}
@media (min-width: 1200px) {
  .not-found-link:hover i{
    margin-left: 0.5rem;
  }
}
.not-found-link i {
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.list-enter-active {
  transition: all 1s ease-in-out;
}

.list-leave-active {
  transition: all 1s ease-in-out;
  transition-delay: 0ms !important;
}

.card-item.list-enter {
  opacity: 0;
  transform: translateY(100px);
}

.card-item.list-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

.card-item.list-leave {
  opacity: 1;
  transform: translateY(0px);
}

.card-item.list-leave-to {
  opacity: 0;
  transform: translateY(200px);
}